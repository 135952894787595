<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="480"
      max-height="400"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card class="overflow-hidden">
        <v-row class="ma-0 ml-4">
          <span
            class="col-10 px-0 grey--text text--darken-4 text-h6 text-truncate"
            >{{ $t("addComment") }}</span
          >
          <v-btn
            class="col-2 my-auto"
            aria-label="cross"
            icon
            @click="closeDialog()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-row no-gutters>
          <v-divider></v-divider>
        </v-row>
        <v-row no-gutters class="mx-4 mt-4 mb-2" justify="center">
          <v-textarea
            v-model="comment"
            outlined
            dense
            required
            rows="2"
            :error-messages="commentErrors"
            @input="$v.comment.$touch()"
            @blur="$v.comment.$touch()"
          >
            <template v-slot:label>
              <span class="red--text"> *</span> {{ $t("comments") }}
            </template></v-textarea
          >
        </v-row>
        <v-row class="mb-2" v-if="!$vuetify.breakpoint.smAndDown">
          <v-divider></v-divider>
        </v-row>
        <v-card-actions v-if="!$vuetify.breakpoint.smAndDown">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            class="text-none rounded-lg elevation-0"
            depressed
            width="90"
            @click="closeDialog"
          >
            {{ $t("cancel") }}
          </v-btn>

          <v-btn
            class="primary text-none rounded-lg elevation-0"
            width="90"
            depressed
            @click="commentTicketSubmit"
            :disabled="$v.$invalid || submitButtonClicked"
          >
            {{ $t("submit") }}
          </v-btn>
        </v-card-actions>
        <v-bottom-navigation
          v-if="$vuetify.breakpoint.smAndDown"
          fixed
          horizontal
          height="70"
          class="px-2 py-4"
          ><v-row justify="space-between" no-gutters>
            <v-btn
              color="primary"
              outlined
              class="mr-2 text-none rounded-lg elevation-0"
              width="50%"
              @click="closeDialog"
            >
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="primary"
              class="text-none font-weight-regular grey--text rounded-lg elevation-0"
              width="50%"
              depressed
              :disabled="$v.$invalid || submitButtonClicked"
              @click="commentTicketSubmit"
              ><span class="white--text text-none">{{ $t("submit") }}</span>
            </v-btn>
          </v-row></v-bottom-navigation
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  validations() {
    return {
      comment: { required, maxLength: maxLength(8192) },
    };
  },
  props: {
    dialog: {
      type: Boolean,
      default: () => false,
    },
    ticketData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      comment: "",
      submitButtonClicked: false,
    };
  },
  methods: {
    async commentTicketSubmit() {
      this.submitButtonClicked = true;

      let commentTicketPayload = {
        ticket_id: this.ticketData.ticket_id,
      };
      let apiData = {
        data: {
          comment: this.comment,
        },
      };
      apiData.ticket = this.ticketData.ticket;
      apiData.center = this.ticketData.centerData.center;
      commentTicketPayload.apiData = apiData;

      let previousRevision = this.ticketData.revision;
      const updateTicketResp = await this.$store.dispatch(
        "revisionCommentTicket",
        commentTicketPayload
      );
      if (updateTicketResp.status === "error") {
        console.log("Error in Comment Ticket Details");
        this.$emit("errorSubmitTicket", updateTicketResp);
      } else {
        this.$emit(
          "commentTicketSuccess",
          this.ticketData.ticket,
          previousRevision
        );
      }
      this.submitButtonClicked = false;
      this.closeDialog();
    },
    closeDialog() {
      this.comment = "";
      this.$emit("closeDialog");
    },
  },
  computed: {
    commentErrors() {
      const errors = [];
      if (!this.$v.comment.$dirty) return errors;
      !this.$v.comment.maxLength && errors.push(this.$t("commentsMaxLimit"));
      !this.$v.comment.required && errors.push(this.$t("Comment is required"));
      return errors;
    },
  },
};
</script>

<style lang="scss" scoped></style>
